import Loading from '../lib/loading';
import jqueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
import { Drawer, Modal, ScrollEffects } from 'pickles';

export default {
  init() {

    window.$ = window.$ || jQuery;
    
    Loading.init();

    // carousels

    Flickity.setJQuery($);
    jqueryBridget('flickity', Flickity, $);
    
    $('.carousel').flickity({
      // options
      groupCells: 1,
      adaptiveHeight: true,
      prevNextButtons: false,
      autoPlay: true,
      wrapAround: true,
      pageDots: false,
    });

    // drawer

    if ( !$('html').hasClass('initialized') ) {
      // drawer
      const drawer = new Drawer({
        contentSelector: '#masthead, #content, #footer'
      });
      $('html').addClass('initialized');
    }
    
    // mobile menu 

    $('.section--product-menu select')
      .off('change')
      .on('change', e => {
        window.location = $(e.currentTarget).val()
      })

    // scroll fx

    new ScrollEffects();

    // subscription form

    const modalManager = new Modal();

    $('form[data-ajax][id=product-form]')
      .on('submit', e => {
        const form = $(e.currentTarget);

        form.addClass('form--submitting');
        form.find('.alert').remove();

        $.getJSON({
          url: env.ajaxUrl,
          type: 'post',
          data: new FormData(e.currentTarget),
          cache: false,
          contentType: false,
          processData: false,          
        })
        .always(response => {
          form.removeClass('form--submitting');

          if ( response.success ) {
            $('#modal-product-message').find('.alert-wrapper').empty().append($('<div class="alert success centered">').html(response.data.message));
            modalManager.open($('#modal-product-message'));
          }
          else {
            form.find('.alert-wrapper').append($('<div class="alert error">').html(response.data.message));
          }
        })

        return false;        
      })
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
